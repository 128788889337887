const MediaQueryCache: Record<string, MediaQueryList> = {};

/**
 *  Create and listen to media query size changes in browser
 *  https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
 */
export const subscribeToMediaQuery = (query: string, callback: (event: MediaQueryListEvent) => void) => {
    // Add query to the object if it does not exist
    if (!MediaQueryCache[query]) {
        MediaQueryCache[query] = window.matchMedia(query);
    }

    // Add change event listener
    MediaQueryCache[query].addEventListener('change', callback);
    callback(new MediaQueryListEvent('media query list event', { media: MediaQueryCache[query].media, matches: MediaQueryCache[query].matches }));

    // Return unsubscribe function which removes event listener
    return () => {
        MediaQueryCache[query].removeEventListener('change', callback);
    };
};

export const defaultQueries = {
    max: {
        /** equal to tailwind `max-md:*` */
        mobile: `(max-width: ${parseInt(screens('md')) - 1}px)`,
        /** equal to tailwind `max-lg:*` */
        tablet: `(max-width: ${parseInt(screens('lg')) - 1}px)`,
    },
    min: {
        /** equal to tailwind `md:*` */
        tablet: `(min-width: ${screens('md', false)})`,
        /** equal to tailwind `lg:*` */
        desktop: `(min-width: ${screens('lg', false)})`,
        /** equal to tailwind `xl:*` */
        desktopLarge: `(min-width: ${screens('xl', false)})`,
    },
};

// Avoid vestibular motion triggers
export const prefersReducedMotion = computed(() => {
    if (import.meta.client) {
        return !!window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }
});
